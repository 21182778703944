import * as React from "react";

export interface AlertProps {
  title: string;
  content: React.ReactNode;
}

export const Alert: React.FunctionComponent<AlertProps> = (props) => {
  return (
    <div className="alert-band">
      <div
        className="alert alert-danger alert-dismissible fade in"
        role="alert"
      >
        <button
          type="button"
          className="close"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">
            <i className="win-icon win-icon-Cancel"></i>
          </span>
        </button>
        <div className="alert-title">{props.title}</div>
        <div className="row">
          <div className="col-xs-24">{props.content}</div>
        </div>
      </div>
    </div>
  );
};
