import * as React from "react";
import {
  EnrollmentDetail,
  DerivedStatus,
  DerivedEnrollmentStatus,
} from "../../containers/base-container";
import { createScope }  from "@harmony/enablers/react/scope-for-react";
import icon from "@harmony/enablers/components/icon/icon";
import partnerCenterTheme  from "@harmony/enablers/themes/partner-center";

const scope = createScope({
  reactInstance: React,
  styles: [partnerCenterTheme],
});

const Icon = scope.forReact(icon);

export var InvitedIcon = (
  <span style={{ color: "green" }}>
    <Icon name="chatinvitefriend"></Icon>
  </span>
);
export var EnrollingPendingPartnerIcon = (
  <span style={{ color: "brown" }}>
    <Icon name="warning"></Icon>
  </span>
);
export var EnrollingPendingMSIcon = (
  <span style={{ color: "blue" }}>
    <Icon name="clock"></Icon>
  </span>
);
export var EnrolledIcon = (
  <span style={{ color: "green" }}>
    <Icon name="completed"></Icon>
  </span>
);
export var IneligibleIcon = (
  <span style={{ color: "red" }}>
    <Icon name="errorbadge"></Icon>
  </span>
);
export var DiscontinuedIcon = <Icon name="blocked"></Icon>;
export var ProgramEndingOnFutureDateIcon = (
  <span style={{ color: "indigo" }}>
    <Icon name="disableupdates"></Icon>
  </span>
);
export var UnknownIcon = (
  <span style={{ color: "red" }}>
    <Icon name="unknown"></Icon>
  </span>
);

export const GetStatusIconForEnrollment = (enrollment: EnrollmentDetail) => {
  switch (DerivedStatus(enrollment)) {
    case DerivedEnrollmentStatus.Invited: {
      return InvitedIcon;
    }
    case DerivedEnrollmentStatus.ActionRequired: {
      return EnrollingPendingPartnerIcon;
    }
    case DerivedEnrollmentStatus.ValidatingEnrollment: {
      return EnrollingPendingMSIcon;
    }
    case DerivedEnrollmentStatus.Enrolling: {
      return EnrollingPendingMSIcon;
    }
    case DerivedEnrollmentStatus.Enrolled: {
      return EnrolledIcon;
    }
    case DerivedEnrollmentStatus.Inactive: {
      return IneligibleIcon;
    }
    case DerivedEnrollmentStatus.Ineligible: {
      return IneligibleIcon;
    }
    case DerivedEnrollmentStatus.ProgramDiscontinued: {
      return DiscontinuedIcon;
    }
    case DerivedEnrollmentStatus.ProgramEndingOnFutureDate: {
      return ProgramEndingOnFutureDateIcon;
    }
    default: {
      return UnknownIcon;
    }
  }
};
