
export class Environment {
    static isModern(): boolean {
        return this.hrefContains("enrollment/summary") || this.hrefContains("modern");
    }

    static isInt(): boolean {
        return this.hrefContains("-int");
    }

    static isTst(): boolean {
        return this.hrefContains("-tst");
    }

    static hrefContains(str: string): boolean {
        return window.location.href.toLocaleLowerCase().indexOf(str.toLocaleLowerCase()) > -1;
    }

    static isWorkspace() : boolean {
        if(document.body !== null && document.body.classList && document.body.classList.contains("modernUX")) {
            return true;
        }        
        return false;
    }
}