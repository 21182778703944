import * as React from "react";
import {
  DerivedEnrollmentStatus,
  DerivedStatus,
  EnrollmentDetail,
  GetProgramDiscontinuationDate,
  IsEligibilityProgram,
  IsInvited,
} from "../../containers/base-container";
import { LocalizationInfo } from "@emt/emt-localization/lib/localization-info";
import { RoutesHelper } from "../../utilities/routesHelper";
import button  from "@harmony/enablers/components/button/button";
import { createScope }  from "@harmony/enablers/react/scope-for-react";
import icon from "@harmony/enablers/components/icon/icon";
import partnerCenterTheme  from "@harmony/enablers/themes/partner-center";
import { GetStatusIconForEnrollment } from "../enrollment-status-icon/enrollment-status-icon";
import { EnrollmentGridProcessedRow } from "./enrollments-grid-types";

const scope = createScope({
  reactInstance: React,
  styles: [partnerCenterTheme],
});

const Button = scope.forReact(button);
const Icon = scope.forReact(icon);

export const AllEnrollmentRows = (
  isEnvironmentModern: boolean,
  gridHeaders: any,
  e: EnrollmentDetail[],
  enrollCallback: (e: EnrollmentDetail) => void,
  localization: LocalizationInfo,
  eligibilityCallback: (e: React.MouseEvent) => void
): EnrollmentGridProcessedRow[] => {
  var result: any = [];
  e.forEach((r, k) => {
    var derivedStatus = DerivedStatus(r);
    result.push({
      id: k,
      enrollCallback,
      eligibilityCallback,
      content: r,
      derivedStatus,
      isEnvironmentModern,
      cells: gridHeaders.reduce(
        (
          cell: { [x: string]: string | number },
          column: { field: string | number; content: any | undefined },
          index: any
        ) => {
          cell[column.field] = getInitialFieldValue(
            r,
            column.field,
            localization,
            derivedStatus
          );
          console.log(index);
          return cell;
        },
        {}
      ),
    });
  });
  return result;
};

export const EnrollmentRowWithHTMLAttributes = (
  r: EnrollmentGridProcessedRow,
  localization: LocalizationInfo
) => {
  let modifiedRow = (
    <>
      <span
        slot={`${localization.strings["TableProgramNameHeader"]}-${r.id}`}
        data-sort-value={r.content.programName}
      >
        {r.content.programLink ? (
          <Button
            external
            appearance={"link"}
            target={"_blank"}
            href={r.content.programLink}
          >
            {r.content.programName}
          </Button>
        ) : (
          r.content.programName
        )}
      </span>
      <span
        slot={`${localization.strings["TablePartnerIdHeader"]}-${r.id}`}
        data-sort-value={
          r.content.partnerId.type + " " + r.content.partnerId.value
        }
      >
        {setPartnerIdCell(r.content.accessViaLegacy, r.isEnvironmentModern)}
        <span className="no-margin">
          {r.content.partnerId.type + " " + r.content.partnerId.value} <br />{" "}
          {r.content.locationName}{" "}
        </span>
      </span>
      <span
        slot={`${localization.strings["TableLocationHeader"]}-${r.id}`}
        data-sort-value={r.content.countryName}
      >
        {r.content.countryName}
      </span>
      <span
        slot={`${localization.strings["TableStatusHeader"]}-${r.id}`}
        data-sort-value={localization.strings[r.derivedStatus.toString()]}
      >
        {EnrollmentStatusInfo(r.content, localization)}
        {IsEligibilityProgram(r.content.offeringGuid) ? (
          <div>
            <Button
              appearance={"link"}
              data-program-name={r.content.programName}
              data-partner-id={r.content.partnerId.value}
              data-program-guid={r.content.offeringGuid}
              onClick={r.eligibilityCallback}
              className="btn-link eligibility-factor-link"
            >
              {localization.strings["SeeEligibilityFactors"]}
            </Button>
          </div>
        ) : (
          <></>
        )}
      </span>
      <span
        slot={`${localization.strings["ActionsRequired"]}-${r.id}`}
        data-sort-value={DerivedStatus(r.content)}
      >
        {r.content.actionRequired
          ? IsInvited(r.content)
            ? getEnrollLink(r.content, r.enrollCallback, localization)
            : getBankAndTaxUpdateLink(r.content, localization)
          : "Not available"}
      </span>
      <span
        slot={`${localization.strings["TableProgramResourcesHeader"]}-${r.id}`}
      >
        {r.content.programGuide ? (
          getGuideLink(r.content, localization)
        ) : (
          <span data-sort-value={"Not available"}>Not available</span>
        )}
      </span>
    </>
  );
  return modifiedRow;
};

export const getBankAndTaxUpdateLink = (
  row: EnrollmentDetail,
  localization: LocalizationInfo
): JSX.Element => {
  let bankAndTaxUpdatePage = RoutesHelper.getBankAndTaxRoute(
    row.partnerId.value + "_" + row.offeringGuid
  );

  if (row.showUpdateBank && row.showUpdateTax) {
    return (
      <Button
        external
        appearance={"link"}
        target={"_blank"}
        href={bankAndTaxUpdatePage}
      >
        {localization.strings["TableStatusUpdateBankAndTaxProfile"]}
      </Button>
    );
  } else if (row.showUpdateBank && row.showUpdateExpiringTax) {
    return (
      <Button
        external
        appearance={"link"}
        target={"_blank"}
        href={bankAndTaxUpdatePage}
      >
        {localization.strings["TableStatusUpdateBankAndExpiringTaxProfile"]}
      </Button>
    );
  } else if (row.showUpdateBank) {
    return (
      <Button
        external
        appearance={"link"}
        target={"_blank"}
        href={bankAndTaxUpdatePage}
      >
        {localization.strings["TableStatusUpdateBankProfile"]}
      </Button>
    );
  } else if (row.showUpdateTax) {
    return (
      <Button
        external
        appearance={"link"}
        target={"_blank"}
        href={bankAndTaxUpdatePage}
      >
        {localization.strings["TableStatusUpdateTaxProfile"]}
      </Button>
    );
  } else if (row.showUpdateExpiringTax) {
    return (
      <Button
        external
        appearance={"link"}
        target={"_blank"}
        href={bankAndTaxUpdatePage}
      >
        {localization.strings["TableStatusUpdateExpiringTaxProfile"]}
      </Button>
    );
  }
  return <></>;
};

export const getEnrollLink = (
  row: EnrollmentDetail,
  enrollCallback: (e: EnrollmentDetail) => void,
  localization: LocalizationInfo
): JSX.Element => {
  return (
    <a
      data-bi-slot="1"
      data-bi-id={"InviteLink-" + row.programName.replace(/\s+/g, "")}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        enrollCallback(row);
      }}
      onKeyUp={(e) => handleKeyUp(e, row, enrollCallback)}
      tabIndex={0}
    >
      {localization.strings["TableStatusEnroll"]}
    </a>
  );
};

export const handleKeyUp = (
  e: React.KeyboardEvent<HTMLAnchorElement>,
  param: any,
  action: (e: any) => void
) => {
  if (e.keyCode === 13) {
    // Enter
    action(param);
  }
};

const getInitialFieldValue = (
  row: EnrollmentDetail,
  field: any,
  localization: LocalizationInfo,
  derivedStatus: DerivedEnrollmentStatus
): string | number => {
  if (row && field) {
    switch (field) {
      case localization.strings["TableProgramNameHeader"]:
        return row.programName;
      case localization.strings["TablePartnerIdHeader"]:
        return row.partnerId.type + " " + row.partnerId.value;
      case localization.strings["TableLocationHeader"]:
        return row.countryName;
      case localization.strings["TableStatusHeader"]:
        return localization.strings[derivedStatus.toString()];
      case localization.strings["ActionsRequired"]:
        return DerivedStatus(row);
      case localization.strings["TableProgramResourcesHeader"]:
        return "Not available";
      default:
        return "";
    }
  }
  return "";
};

const setPartnerIdCell = (
  accessViaLegacy: boolean,
  isEnvironmentModern: boolean
) => {
  if (!(isEnvironmentModern && accessViaLegacy)) {
    return null;
  }
  return <span className="win-icon win-icon-marble-sm status-warning"></span>;
};

export const toSortableCurrencyAmount = (amount: number) => {
  return amount && !isNaN(amount) ? (amount * 100).toString().split(".")[0] : 0;
};

export const getGuideLink = (
  enrollment: EnrollmentDetail,
  localization: LocalizationInfo
) => {
  if (enrollment.programGuide) {
    return (
      <Button
        appearance={"link"}
        target="_blank"
        href={enrollment.programGuide.path}
      >
        {localization.strings["TableProgramGuide"]}
        <Icon slot="end" name="download" />
      </Button>
    );
  }
  return <></>;
};

const EnrollmentStatusInfo = (
  enrollment: EnrollmentDetail,
  localization: LocalizationInfo
) => {
  const status = DerivedStatus(enrollment);
  let text = localization.strings[status.toString()];
  if (status == DerivedEnrollmentStatus.ProgramEndingOnFutureDate) {
    text = localization.format(
      "ProgramEndingOnFutureDate",
      GetProgramDiscontinuationDate(
        enrollment.offeringGuid
      ).toLocaleDateString()
    );
  }
  return (
    <>
      {GetStatusIconForEnrollment(enrollment)} {` ${text}`}
    </>
  );
};
