import { EnrollmentAppStore } from "../redux/enrollment-store/store";
import { PaymentAndTaxStatus, Enrollment, PayoutReport, Invitation, PartnerId, ProfileAction } from "../models/model";
import {
    Offering,
    Metadata,
    ProgramDescriptionDetail,
    OfferingTimeline,
    ProgramResource
} from "@emt/emt-metadata-client";
import { RoutesHelper } from "../utilities/routesHelper";
import { UxConstants } from "../utilities/uxConstants";

/**
 * A mapping of offeringsGuids and their discontinuation dates.
 * NOTE: The month part of the javascript date object starts from 0
 * so december is treated as 11, instead of 12
 * see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/getMonth
 */
const DiscontinuedPrograms: {[programId: string]: Date} = {
    "f3460c1a-b544-4e8c-8875-b9a8d50c611a": new Date(2020, 9, 5), // OsaSell - direct
    "be2b5378-041f-4a8f-b228-1d9a4c74098f": new Date(2020, 9, 5), //Azure Incentives
    "1233086e-7c3a-4ce4-8b76-24ff52ea0aac": new Date(2020, 9, 5), // CRI
    "29b6a0a1-5b47-4711-bd15-41ca0a777173": new Date(2020, 11, 31), // Managed Reseller
}

const EligibilityProgramList: string[] = [
    // CSP programs
    "0bfa81a6-9e1e-437b-9600-bcf22d50781a",
    "e5955cfd-5c6a-41ce-8639-c0f40e0a4e42",
    "f86af17c-0021-4d31-a8d5-651ad0610c0b"
];

export const IsEligibilityProgram = (guid: string): boolean => {
    return EligibilityProgramList.includes(guid.toLowerCase());
}

export enum DerivedEnrollmentStatus {
    Unknown = "Unknown",
    Invited = "Invited",
    ActionRequired = "ActionRequired",
    ValidatingEnrollment = "ValidatingEnrollment",
    Enrolling = "Enrolling",
    Enrolled = "Enrolled",
    Inactive = "Inactive",
    Ineligible = "Ineligible",
    ProgramDiscontinued = "ProgramDiscontinued",
    ProgramEndingOnFutureDate = "ProgramEndingOnFutureDate"
}

export class EnrollmentDetail {
    offeringId: string;
    offeringGuid: string;
    programName: string;
    programLink: string;
    countryName: string;
    locationName: string;
    partnerId: PartnerId;
    status: string;
    showUpdateBank: boolean;
    showUpdateTax: boolean;
    showUpdateExpiringTax: boolean;
    programDescriptionDetails: ProgramDescriptionDetail[];
    programGuide: ProgramResource | null;
    actionRequired: boolean;    
    currencyCode: string;
    isActive: boolean;
    accessViaLegacy: boolean;
    invitationOfferingTimeline: OfferingTimeline | null;
}

export const GetProgramDiscontinuationDate = (offeringId: string) => {
    return DiscontinuedPrograms[offeringId.toLowerCase()];
}

export const IsInvited = (e: EnrollmentDetail): boolean => {
    return (e.status == "Invited" && e.isActive);
}

export const DerivedStatus = (e: EnrollmentDetail): DerivedEnrollmentStatus => {
    const offeringGuid = e.offeringGuid.toLowerCase();

    if (offeringGuid in DiscontinuedPrograms) {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        if (today <= DiscontinuedPrograms[offeringGuid]){
            return DerivedEnrollmentStatus.ProgramEndingOnFutureDate;
        }
        return DerivedEnrollmentStatus.ProgramDiscontinued;
    }
    if (IsInvited(e)) {
        return DerivedEnrollmentStatus.Invited;
    }
    if ((e.status == "Enrolling" || e.status == "Enrolled") && (e.showUpdateBank || e.showUpdateTax) && e.isActive) {
        return DerivedEnrollmentStatus.ActionRequired;
    }
    if (e.status == "Enrolling" && e.showUpdateBank === false && e.showUpdateTax === false && e.isActive) {
        return DerivedEnrollmentStatus.ValidatingEnrollment;
    }
    if (e.status == "Enrolling" && !e.showUpdateBank && !e.showUpdateTax && e.isActive) {
        //shows in cases where user is WOBO or Non-Admin and enrolling status is ambiguous.
        return DerivedEnrollmentStatus.Enrolling;
    }
    if (e.status == "Enrolled" && !e.showUpdateBank && !e.showUpdateTax && e.isActive) {
        return DerivedEnrollmentStatus.Enrolled;
    }
    if (!e.isActive && e.status != "Invited" && !(offeringGuid in DiscontinuedPrograms)) {
        if (EligibilityProgramList.includes(offeringGuid)) {
            return DerivedEnrollmentStatus.Ineligible
        }

        return DerivedEnrollmentStatus.Inactive
    }
    return (DerivedEnrollmentStatus.Unknown)
};

export class InvitationDetail {
    offeringId: string;
    offeringGuid: string;
    programName: string;
    countryName: string;
    locationName: string;
    partnerId: PartnerId;
    offeringTimeline: OfferingTimeline | null;
    programGuide: ProgramResource | null;
    programDescriptionDetails: ProgramDescriptionDetail[];
}

export class BaseContainer {
    static getEnrollmentDetails(store: EnrollmentAppStore): EnrollmentDetail[] {
        return store.enrollments
            .map(
                (e: Enrollment): EnrollmentDetail => {
                    const matchedOffering = BaseContainer.getOfferingForId(e.offeringId, store.metadata);
                    const bankAndtaxStatus = BaseContainer.getPaymentStatusForId(
                        e.offeringId,
                        e.partnerId,
                        store.bankAndTaxStatuses
                    );
                    const payoutReport = BaseContainer.getPayoutReportForId(
                        e.offeringId,
                        e.partnerId,
                        store.payoutReports
                    );

                    return {
                        offeringId: e.offeringId,
                        offeringGuid: e.offeringGuid,
                        countryName: e.countryName,
                        locationName: e.partnerLocation,
                        status: BaseContainer.getStatus(e, store),
                        programName: matchedOffering ? matchedOffering.name : "",
                        partnerId: e.partnerId,
                        programLink: BaseContainer.getProgramLink(matchedOffering, e),
                        showUpdateBank: BaseContainer.shouldShowUpdateBank(store, bankAndtaxStatus),
                        showUpdateTax: BaseContainer.shouldShowUpdateTax(store, bankAndtaxStatus),
                        showUpdateExpiringTax: BaseContainer.shouldShowUpdateExpiringTax(store, bankAndtaxStatus),
                        programGuide: BaseContainer.getProgramGuide(matchedOffering),
                        programDescriptionDetails: BaseContainer.getProgramDescriptionDetails(matchedOffering),
                        actionRequired: BaseContainer.isActionRequired(store, bankAndtaxStatus),
                        currencyCode: payoutReport ? payoutReport.currencyCode : "USD",
                        isActive: e.isActive,
                        accessViaLegacy: e.accessViaLegacy,
                        invitationOfferingTimeline: null
                    };
                }
            )
            .filter((content: EnrollmentDetail) => content.programName !== "");
    }

    static getInvitationDetails(store: EnrollmentAppStore): InvitationDetail[] {
        return store.invitations
            .map(
                (i: Invitation): InvitationDetail => {
                    const matchedOffering = BaseContainer.getOfferingForId(i.offeringId, store.metadata);
                    const offeringTimeline = BaseContainer.getOfferingTimeline(matchedOffering, i.offeringTimelineCode);
                    return {
                        countryName: i.countryName,
                        locationName: i.partnerLocation,
                        partnerId: i.partnerId,
                        offeringId: i.offeringId,
                        offeringGuid: i.offeringGuid,
                        programName: matchedOffering ? matchedOffering.name : "",
                        offeringTimeline: offeringTimeline,
                        programDescriptionDetails: BaseContainer.getProgramDescriptionDetails(matchedOffering),
                        programGuide: BaseContainer.getProgramGuide(matchedOffering)
                    };
                }
            )
            .filter((content: InvitationDetail) => content.programName !== "" && content.offeringTimeline != null);
    }

    private static isActionRequired(store: EnrollmentAppStore, bankAndTaxStatus: PaymentAndTaxStatus | null) {
        const bankUpdateRequired = BaseContainer.shouldShowUpdateBank(store, bankAndTaxStatus);
        const taxUpdateRequired = BaseContainer.shouldShowUpdateTax(store, bankAndTaxStatus);
        const expiringtaxUpdateRequired = BaseContainer.shouldShowUpdateExpiringTax(store, bankAndTaxStatus);

        return bankUpdateRequired || taxUpdateRequired || expiringtaxUpdateRequired;
    }

    private static getProgramLink(offering: Offering | null, enrollmentDetail: Enrollment): string {
        if (!offering 
            || enrollmentDetail.enrollmentState !== "Enrolled"
            || !offering.offeringGuid
            || UxConstants.ProgramsToHideProgramLink.find(
                programGuid => programGuid.toUpperCase() === offering.offeringGuid.toUpperCase()
            )) {
            return "";
        }

        return RoutesHelper.getInsightsProgramPageLink(offering.offeringGuid);
    }

    private static getProgramGuide(offering: Offering | null): ProgramResource | null {
        if (offering == null) {
            return null;
        }

        const guide = offering.programResources.filter(res => res.programResourceType.replace(" ", "").toUpperCase() == "PROGRAMGUIDE");
        if (guide.length == 0) {
            return null;
        }
        return guide[0];
    }

    private static shouldShowUpdateTax(
        store: EnrollmentAppStore,
        bankAndTaxStatus: PaymentAndTaxStatus | null
    ): boolean {
        // if the tax data has not been fetched yet, dont show anything yet
        if (!store.dataFetchingStatus.bankAndTax) {
            return false;
        }

        // if there was an error fetching tax status, dont show update tax
        if (store.errorStatus.bankAndTax) {
            return false;
        }

        // if the api didn't return a bank and tax status, that would mean they don't have permission. So don't show update bank or tax
        if (!bankAndTaxStatus) {
            return false;
        }

        // if the tax instrument action is pending partner, then show update tax
        if (bankAndTaxStatus.taxInstrumentAction === ProfileAction.PendingPartner) {
            return true;
        }

        // if the tax is expired, then show update tax
        if(bankAndTaxStatus.taxExpired){
            return true;
        }
        return false;
    }
    private static shouldShowUpdateExpiringTax(
        store: EnrollmentAppStore,
        bankAndTaxStatus: PaymentAndTaxStatus | null
    ): boolean {
         // if the tax data has not been fetched yet, dont show anything yet
         if (!store.dataFetchingStatus.bankAndTax) {
            return false;
        }

        // if there was an error fetching tax status, dont show update tax
        if (store.errorStatus.bankAndTax) {
            return false;
        }

        // if the api didn't return a bank and tax status, that would mean they don't have permission. So don't show update bank or tax
        if (!bankAndTaxStatus) {
            return false;
        }
        
        //if the tax is expiring within 95 days, then show update expiring tax
        if(bankAndTaxStatus.taxIsExpiring){
            return true;
        }
        return false;
    }
    private static shouldShowUpdateBank(
        store: EnrollmentAppStore,
        bankAndTaxStatus: PaymentAndTaxStatus | null
    ): boolean {
        // if the bank data has not been fetched yet, dont show anything yet
        if (!store.dataFetchingStatus.bankAndTax) {
            return false;
        }

        // if there was an error fetching bank status, dont show update bank
        if (store.errorStatus.bankAndTax) {
            return false;
        }

        // if the api didn't return a bank and tax status, that would mean they don't have permission. So don't show update bank or tax
        if (!bankAndTaxStatus) {
            return false;
        }

        // if the bank instrument action is pending partner, then show update tax
        if (bankAndTaxStatus.paymentInstrumentAction === ProfileAction.PendingPartner) {
            return true;
        }
        return false;
    }

    private static getStatus(enrollment: Enrollment, store: EnrollmentAppStore): string {
        // if the bank and tax status data has not been fetched yet, return empty
        return store.dataFetchingStatus.bankAndTax &&
            store.dataFetchingStatus.enrollment &&
            !store.errorStatus.bankAndTax &&
            !store.errorStatus.enrollment
            ? enrollment.enrollmentState
            : "Unknown";
    }

    private static getOfferingForId(offeringId: string, metadata: Metadata): Offering | null {
        const matchedOfferings = metadata.offerings.filter(m => m.id == offeringId);
        if (matchedOfferings.length != 1) {
            return null;
        }
        return matchedOfferings[0];
    }

    private static getProgramDescriptionDetails(offering: Offering | null): ProgramDescriptionDetail[] {
        if (!offering) {
            return [];
        }

        return offering.programDescriptionDetails;
    }

    //TODO: Which offeringTimeline to pick when we add multiple offeringTimelines?
    private static getOfferingTimeline(offering: Offering | null, offeringPeriodCode: string): OfferingTimeline | null {
        if (!offering) {
            return null;
        }

        return offering.offeringTimelines.filter(t => t.code === offeringPeriodCode)[0];
    }

    private static getPaymentStatusForId(
        offeringId: string,
        partnerId: PartnerId,
        bankAndTaxStatuses: PaymentAndTaxStatus[]
    ): PaymentAndTaxStatus | null {
        const matchedOfferings = bankAndTaxStatuses.filter(
            m =>
                m.offeringId == offeringId &&
                m.partnerId.type == partnerId.type &&
                m.partnerId.value == partnerId.value
        );
        if (matchedOfferings.length != 1) {
            return null;
        }
        return matchedOfferings[0];
    }

    private static getPayoutReportForId(
        offeringId: string,
        partnerId: PartnerId,
        payoutReports: PayoutReport[]
    ): PayoutReport | null {
        const matchedReport = payoutReports.filter(
            m =>
                m.offeringId == offeringId &&
                m.partnerId.type == partnerId.type &&
                m.partnerId.value == partnerId.value
        );
        if (matchedReport.length != 1) {
            return null;
        }
        return matchedReport[0];
    }
}
