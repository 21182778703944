import * as React from "react";
import { LocalizationContext } from "../../context/LocalizationContext";
import {
  DerivedEnrollmentStatus,
  DerivedStatus,
  EnrollmentDetail,
  InvitationDetail,
} from "../../containers/base-container";
import { AIContext } from "../../context/AIContext";
import { LocalizationInfo } from "@emt/emt-localization/lib/localization-info";
import button  from "@harmony/enablers/components/button/button";
import buttonGroup  from "@harmony/enablers/components/button-group/button-group";
//import { createScope }  from "@harmony/enablers/utilities/scope";
import { createScope }  from "@harmony/enablers/react/scope-for-react";
import dataGrid  from "@harmony/enablers/components/data-grid/data-grid";
import divider  from "@harmony/enablers/components/divider/divider";
import dropdown  from "@harmony/enablers/components/dropdown/dropdown";
import filterPill  from "@harmony/enablers/components/filter-pill/filter-pill";
import icon from "@harmony/enablers/components/icon/icon";
import menu from "@harmony/enablers/components/menu/menu";
import menuItem from "@harmony/enablers/components/menu-item/menu-item";
import pagination from "@harmony/enablers/components/pagination/pagination";
import partnerCenterTheme  from "@harmony/enablers/themes/partner-center";
import searchBox from "@harmony/enablers/components/search-box/search-box";
import visuallyHidden from "@harmony/enablers/components/visually-hidden/visually-hidden";
import {
  AllEnrollmentRows,
  EnrollmentRowWithHTMLAttributes,
} from "./enrollments-grid-helpers";
import {
  EnrollmentGridProcessedRow,
  EnrollmentsGridFilterItem,
} from "./enrollments-grid-types";
import { EligibilityModal } from "../modal/eligibility-modal";

export interface EnrollmentsGridProps {
  enrollments: EnrollmentDetail[];
  invitations: InvitationDetail[];
  enrollCallback: (enrollmentDetail: EnrollmentDetail) => void;
  isLoading: boolean;
  isEnvironmentModern: boolean;
}

export interface EnrollmentsGridState {
  allEnrollments: EnrollmentDetail[];
  filterItems: EnrollmentsGridFilterItem[];
  filteredTableEnrollments: EnrollmentDetail[];
  showEligibilityModal: boolean;
  eligibilityPartnerNumber: string | null;
  eligibilityProgramGuid: string | null;
  eligibilityProgramName: string | null;
  tableHeaders: any;
  tableRows: EnrollmentGridProcessedRow[];
  processedTableRows: EnrollmentGridProcessedRow[];
  sortKey: string;
  sortType: number;
  searchQuery: string;
  pageNumber: number;
  pageSize: number;
  pageCount: number;
  pagedRows: EnrollmentGridProcessedRow[];
}

const scope = createScope({
  reactInstance: React,
  styles: [partnerCenterTheme],
});

const DataGrid = scope.forReact(dataGrid);
const Pagination = scope.forReact(pagination);
const Divider = scope.forReact(divider);
const Dropdown = scope.forReact(dropdown);
const Button = scope.forReact(button);
const Menu = scope.forReact(menu);
const MenuItem = scope.forReact(menuItem);
const Icon = scope.forReact(icon);
const SearchBox = scope.forReact(searchBox);
const ButtonGroup = scope.forReact(buttonGroup);
const FilterPill = scope.forReact(filterPill);
const VisuallyHidden = scope.forReact(visuallyHidden);

export class EnrollmentsGrid extends React.Component<
  EnrollmentsGridProps,
  EnrollmentsGridState
> {
  constructor(props: EnrollmentsGridProps) {
    super(props);

    //Set the initial state
    this.state = {
      allEnrollments: [],
      filteredTableEnrollments: [],
      filterItems: [],
      pageNumber: 1,
      showEligibilityModal: false,
      eligibilityPartnerNumber: "",
      eligibilityProgramGuid: "",
      eligibilityProgramName: "",
      tableHeaders: [],
      tableRows: [],
      processedTableRows: [],
      sortKey: "",
      sortType: 1,
      searchQuery: "",
      pageSize: 25,
      pagedRows: [],
      pageCount: 1,
    };
  }

  componentWillReceiveProps(nextProps: EnrollmentsGridProps) {
    //Convert invitations to enrollment type so that we can handle them with existing logic.
    const invitationEnrollments = nextProps.invitations.map(
      (i): EnrollmentDetail => {
        return {
          offeringId: i.offeringId,
          offeringGuid: i.offeringGuid,
          programName: i.programName,
          programLink: "",
          countryName: i.countryName,
          locationName: i.locationName,
          partnerId: i.partnerId,
          status: "Invited",
          showUpdateBank: false,
          showUpdateTax: false,
          showUpdateExpiringTax: false,
          programDescriptionDetails: i.programDescriptionDetails,
          programGuide: i.programGuide,
          actionRequired: true,
          currencyCode: "USD",
          isActive: true,
          accessViaLegacy: false,
          invitationOfferingTimeline: i.offeringTimeline,
        };
      }
    );

    //Create one set of all enrollments and invitations, also set the initial filtered state.
    if (
      nextProps.enrollments.concat(invitationEnrollments) !=
      this.state.allEnrollments
    ) {
      this.setState(
        {
          allEnrollments: nextProps.enrollments.concat(invitationEnrollments),
          filteredTableEnrollments: nextProps.enrollments.concat(
            invitationEnrollments
          ),
        },
        this.updateTableState
      );
    }
  }

  // Render the component
  render(): JSX.Element {
    return (
      <LocalizationContext.Consumer>
        {(localization) => (
          <AIContext.Consumer>
            {(appInsights) => this.renderContent(localization, appInsights)}
          </AIContext.Consumer>
        )}
      </LocalizationContext.Consumer>
    );
  }

  private derivePagedRows = () => {
    // Process pagination controls
    const pagedRows = this.state.processedTableRows.slice(
      (this.state.pageNumber - 1) * this.state.pageSize,
      this.state.pageNumber * this.state.pageSize
    );
    const pageCount = Math.ceil(
      this.state.processedTableRows.length / this.state.pageSize
    );

    this.setState({ pagedRows, pageCount });
  };

  /**
   * define the set of filter items to show on this table
   */
  private filterItems = (
    localization: LocalizationInfo
  ): EnrollmentsGridFilterItem[] => {
    return [
      {
        key: 1,
        name: localization.strings["FilterActionRequired"],
        filterPredicate: (e: EnrollmentGridProcessedRow) =>
          e.content.actionRequired,
      },
      {
        key: 2,
        name: localization.strings["FilterInvitations"],
        filterPredicate: (e: EnrollmentGridProcessedRow) =>
          DerivedStatus(e.content) == DerivedEnrollmentStatus.Invited,
      },
    ];
  };

  private eligibilityModalCallback = (e: React.MouseEvent) => {
    this.setState({
      showEligibilityModal: true,
      eligibilityPartnerNumber: (e.target as HTMLButtonElement).getAttribute(
        "data-partner-id"
      ),
      eligibilityProgramGuid: (e.target as HTMLButtonElement).getAttribute(
        "data-program-guid"
      ),
      eligibilityProgramName: (e.target as HTMLButtonElement).getAttribute(
        "data-program-name"
      ),
    });
  };

  private updateTableState() {
    const rows = AllEnrollmentRows(
      this.props.isEnvironmentModern,
      this.state.tableHeaders,
      this.state.filteredTableEnrollments,
      this.props.enrollCallback,
      this.context,
      this.eligibilityModalCallback
    );
    this.setState(
      {
        tableHeaders: this.enrollmentHeaders(this.context),
        tableRows: rows,
        processedTableRows: rows,
      },
      this.processGridRows
    );
  }

  private renderContent(localization: LocalizationInfo, appInsights: any) {
    return (
      <>
        <h3 className="spacer-xs-bottom">All incentive programs</h3>
        <Divider role="presentation" className="spacer-xs-bottom" />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            flexDirection: "row",
          }}
        >
          <div style={{ display: "flex", gap: "10px" }}>
            {this.state.filterItems.length > 0 &&
              this.state.filterItems.map((item: EnrollmentsGridFilterItem) => (
                <ButtonGroup key={item.name} style={{ paddingTop: 4 }}>
                  <FilterPill>{item.name}</FilterPill>
                  <FilterPill
                    onclick={(_) => this.handleRemoveFilter(item.key)}
                    clear
                  >
                    <VisuallyHidden>Remove filter</VisuallyHidden>
                  </FilterPill>
                </ButtonGroup>
              ))}
            {this.state.filterItems.length > 0 && (
              <Button
                onClick={(_) => this.handleClearAll()}
                appearance={"command"}
              >
                <Icon slot="start" name="clearfilter" />
                Clear all
              </Button>
            )}
          </div>
          <div style={{ display: "flex" }}>
            <div>
              <Dropdown>
                <Button appearance={"command"} slot="trigger" caret>
                  <Icon slot="start" name="filter" color="blue" />
                  Filter
                </Button>
                {
                  <Menu>
                    {this.filterItems(localization)
                      .filter(
                        (p) =>
                          this.state.filterItems.findIndex(
                            (t) => t.key === p.key
                          ) === -1
                      )
                      .map((item: EnrollmentsGridFilterItem) => (
                        <MenuItem
                          onClick={(e) => this.handleFilterSelection(e, item)}
                        >
                          {item.name}
                        </MenuItem>
                      ))}
                  </Menu>
                }
              </Dropdown>
            </div>
            <div>
              <SearchBox
                placeholder="Search and press enter"
                onHeSearch={this.handleSearchQuery}
              />
            </div>
          </div>
        </div>
        {localization && appInsights && (
          <>
            <div className="spacer-xs-bottom">
              <DataGrid
                loading={this.props.isLoading}
                rows={this.state.pagedRows}
                columns={this.state.tableHeaders}
                onHeSort={this.handleGridSort}
              >
                <span slot="no-records">No values were returned.</span>
                {this.state.pagedRows.map(
                  (row: {
                    id: number;
                    isEnvironmentModern: boolean;
                    derivedStatus: DerivedEnrollmentStatus;
                    content: any;
                    enrollCallback: (e: EnrollmentDetail) => void;
                    eligibilityCallback: (e: React.MouseEvent) => void;
                    cells: {};
                  }) => {
                    return EnrollmentRowWithHTMLAttributes(row, localization);
                  }
                )}
              </DataGrid>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                {this.state.processedTableRows &&
                  this.state.processedTableRows.length > 0 &&
                  (this.getTotalRowsPagesHold() || 0) > 0 &&
                  (this.state.processedTableRows.length || 0) > 0 && (
                    <span style={{ paddingTop: 20 }}>
                      {this.getTotalRowsPagesHold()} of{" "}
                      {this.state.processedTableRows.length} results
                    </span>
                  )}

                {Math.ceil(
                  this.state.processedTableRows.length / this.state.pageSize
                ) > 1 ? (
                  <Pagination
                    slot="pagination"
                    value={this.state.pageNumber}
                    page-count={Math.ceil(
                      this.state.processedTableRows.length / this.state.pageSize
                    )}
                    onHePageChange={this.handlePageChange}
                  ></Pagination>
                ) : (
                  <div />
                )}

                <Dropdown style={{ paddingTop: 20 }}>
                  <Button slot="trigger" caret>
                    {this.state.pageSize} results
                  </Button>
                  <Menu>
                    {[5, 25, 50, 100, 200].map((item: number) => (
                      <MenuItem
                        onClick={(_) =>
                          this.setState(
                            { pageSize: item, pageNumber: 1 },
                            this.derivePagedRows
                          )
                        }
                      >
                        {item} results
                      </MenuItem>
                    ))}
                  </Menu>
                </Dropdown>
              </div>
              <EligibilityModal
                isVisible={this.state.showEligibilityModal}
                localization={localization}
                mpnNumber={this.state.eligibilityPartnerNumber}
                programGuid={this.state.eligibilityProgramGuid}
                programName={this.state.eligibilityProgramName}
                onDismiss={() => {
                  this.setState({ showEligibilityModal: false });
                }}
              />
            </div>
          </>
        )}
      </>
    );
  }

  private getTotalRowsPagesHold = (): number => {
    return this.state.pageNumber * this.state.pageSize <
      this.state.processedTableRows.length
      ? this.state.pageNumber * this.state.pageSize
      : this.state.processedTableRows.length;
  };

  private handlePageChange = (e: any) => {
    this.setState({ pageNumber: e.detail }, this.derivePagedRows);
  };

  private handleSearchQuery = (e: any) => {
    this.setState(
      { searchQuery: e.target.value, pageNumber: 1 },
      this.processGridRows
    );
  };

  private handleClearAll = () => {
    this.setState({ filterItems: [], pageNumber: 1 }, this.processGridRows);
  };

  private handleRemoveFilter = (key: number) => {
    let newFilterItems =
      this.state.filterItems.filter((p) => p.key !== key) || [];
    this.setState(
      { filterItems: newFilterItems, pageNumber: 1 },
      this.processGridRows
    );
  };

  private handleFilterSelection = (e: any, item: EnrollmentsGridFilterItem) => {
    console.log(e);
    let filterItemsModified = [...this.state.filterItems];
    if (filterItemsModified.indexOf(item) === -1) {
      filterItemsModified.push(item);
    }
    this.setState(
      { filterItems: filterItemsModified, pageNumber: 1 },
      this.processGridRows
    );
  };

  private processGridRows = () => {
    var processedRows = [...this.state.tableRows];
    // Search rows
    processedRows =
      processedRows.filter(
        (p) =>
          this.getRowString(p).indexOf(
            this.state.searchQuery.trim().toLowerCase()
          ) !== -1
      ) || [];

    // Filter rows
    this.state.filterItems.forEach((item: EnrollmentsGridFilterItem) => {
      console.log(item);
      processedRows =
        processedRows.filter((p) => item.filterPredicate(p)) || [];
    });

    // Sort rows
    processedRows.sort((a, b) => {
      const fieldA = a.cells[this.state.sortKey];
      const fieldB = b.cells[this.state.sortKey];
      const isAscending = this.state.sortType;

      if (fieldA > fieldB) {
        return isAscending ? 1 : -1;
      }
      if (fieldA < fieldB) {
        return isAscending ? -1 : 1;
      }
      return 0;
    });

    this.setState({ processedTableRows: processedRows }, this.derivePagedRows);
  };

  private getRowString = (p: EnrollmentGridProcessedRow): String => {
    return (
      p.content.programName +
      "," +
      p.content.partnerId.type +
      "," +
      p.content.partnerId.value +
      "," +
      p.content.locationName +
      "," +
      p.content.countryName +
      "," +
      p.derivedStatus.toString()
    ).toLowerCase();
  };

  private enrollmentHeaders = (localization: LocalizationInfo): any => {
    if (localization) {
      return [
        {
          field: localization.strings["TableProgramNameHeader"],
          content: localization.strings["TableProgramNameHeader"],
          sortable: true,
          display: { maxWidth: "200px" },
        },
        {
          field: localization.strings["TablePartnerIdHeader"],
          content: localization.strings["TablePartnerIdHeader"],
          sortable: true,
          display: { maxWidth: "200px" },
        },
        {
          field: localization.strings["TableLocationHeader"],
          content: localization.strings["TableLocationHeader"],
          sortable: true,
          display: { maxWidth: "200px" },
        },
        {
          field: localization.strings["TableStatusHeader"],
          content: localization.strings["TableStatusHeader"],
          sortable: true,
          display: { maxWidth: "200px" },
        },
        {
          field: localization.strings["ActionsRequired"],
          content: localization.strings["ActionsRequired"],
          sortable: true,
          display: { maxWidth: "200px" },
        },
        {
          field: localization.strings["TableProgramResourcesHeader"],
          content: localization.strings["TableProgramResourcesHeader"],
          sortable: true,
          display: { maxWidth: "200px" },
        },
      ];
    }
  };

  private handleGridSort = (e: any) => {
    this.setState(
      { sortKey: e.detail.sortBy, sortType: e.detail.isAscending },
      this.processGridRows
    );
  };
}

EnrollmentsGrid.contextType = LocalizationContext;
