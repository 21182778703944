import * as React from "react";
import { EnrollmentClient } from "../../clients/enrollment-client";
import { LocalizationInfo } from "@emt/emt-localization/lib/localization-info";
import { RoleInformationForUser } from "../../models/model";
import {
  SortableTable,
  SortableTableRow,
  SortableTableColum,
} from "../sortable-table/sortable-table";

export interface PermissionNotificationProps {
  localization: LocalizationInfo;
  children: React.ReactNode;
}

export function PermissionNotification(props: PermissionNotificationProps) {
  const enrollmentClient = new EnrollmentClient();
  const [userPermissions, setUserPermissions] =
    React.useState<RoleInformationForUser | null>(null);
  const headers: string[] = ["Name", "Contact"];

  React.useEffect(() => {
    fetchData();
  }, []);

  function fetchData() {
    enrollmentClient
      .getRolesPermissionsForUserAwareness()
      .then((role: RoleInformationForUser) => {
        setUserPermissions(role);
      })
      .catch((error: any) => {
        console.log(error);
      });
  }
  return (
    <>
      <div>
        {userPermissions &&
          userPermissions!.responseState ==
            "ReportNoPermissionWithGlobalAdminList" && (
            <div>
              <p>
                {
                  props.localization.strings[
                    "NoPermissionMessageWithGloballist"
                  ]
                }
              </p>
              {userPermissions.tableOfContacts !== null && fillTable()}
              <br />
            </div>
          )}

        {userPermissions &&
          userPermissions!.responseState ==
            "ReportMissingIncentivePermissions" && (
            <p>{props.localization.strings["NoIncentivePermissionMessage"]}</p>
          )}
      </div>
      {!userPermissions ||
        (userPermissions &&
          userPermissions!.responseState == "NoAwarenessNeeded" &&
          props.children)}
    </>
  );

  function fillTable() {
    const rows = userPermissions!.tableOfContacts
      .filter((i) => i.displayName && i.userPrincipalName)
      .map((row): SortableTableRow => {
        var columns: SortableTableColum[] = [];
        columns.push({
          content: <>{row.displayName}</>,
          sortValue: row.displayName,
        });
        columns.push({
          content: <>{row.userPrincipalName}</>,
          sortValue: row.userPrincipalName,
        });
        return { columns: columns };
      });

    return (
      <SortableTable
        rows={rows}
        headers={headers}
        defaultSortIndex={0}
        noContentMessage=""
      />
    );
  }
}
