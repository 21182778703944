import button from "@harmony/enablers/components/button/button";
import { createScope }  from "@harmony/enablers/react/scope-for-react";
import icon from "@harmony/enablers/components/icon/icon";
import partnerCenterTheme  from "@harmony/enablers/themes/partner-center";
import React = require("react");

const scope = createScope({
    reactInstance: React,
    styles: [partnerCenterTheme]
  });

const Button = scope.forReact(button);
const Icon = scope.forReact(icon);

export function transformToClickableLink(text: string, url: string): JSX.Element {
    if (!text) {
        return <></>;
    }
    const splitText = text.split("<a>");
    if (splitText.length < 2) {
        return <>{text}</>;
    }

    const linkText = splitText[1].split("</a>");
    if (linkText.length < 2) {
        return <>{text}</>;
    }

    return (
        <>
            {splitText[0]}
            <Button href={url}
                target="_blank" appearance={"link"}
                style={{fontSize:'var(--he-font-size-small)'}}>
                {linkText[0]}
                <Icon slot="end" name="download" style={{fontSize:'var(--he-font-size-small)'}} />
            </Button>
            {linkText[1]}
        </>
    );
}

export function splitTextIntoMultipleLines(text: string, textToReplace: string): JSX.Element {
    if (!text) {
        return <></>;
    }

    var splitText = text.split(textToReplace);
    const splitTexts = splitText.map((splitText, index) => <div key={index}> {splitText} </div>);
    return <div className="spacer-xs-top">{splitTexts}</div>;
}