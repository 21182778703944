import * as React from "react";
require("./sortable-table.css");

export class SortableTableProperties {
  headers: string[] = [];
  rows: SortableTableRow[] = [];
  defaultSortIndex: number = 0;
  noContentMessage: string = "";
}

export class SortableTableRow {
  columns: SortableTableColum[];
}

export class SortableTableColum {
  content: JSX.Element;
  sortValue: string | number;
}

class SortableTableState {
  currentSortIndex: number = 0;
  isAscending: boolean = true;
}

export class SortableTable extends React.Component<
  SortableTableProperties,
  SortableTableState
> {
  constructor(props: SortableTableProperties) {
    super(props);
    const defaultState = new SortableTableState();
    this.state = {
      ...defaultState,
      currentSortIndex: this.props.defaultSortIndex || 0,
    };
  }

  render(): JSX.Element {
    const sortedRows = this.sortRows();
    return !!this.props.rows.length ? (
      <table className="data-table table sortable-table win-color-bg-0">
        {this.header()}
        {this.body(sortedRows)}
      </table>
    ) : (
      <span className="spacer-md-top">{this.props.noContentMessage}</span>
    );
  }

  private sortRows(): SortableTableRow[] {
    const state = this.state;
    return this.props.rows.sort((a: SortableTableRow, b: SortableTableRow) => {
      const aSortValue = a.columns[state.currentSortIndex].sortValue;
      const bSortValue = b.columns[state.currentSortIndex].sortValue;
      if (state.isAscending) {
        return this.compare(aSortValue, bSortValue);
      } else {
        return this.compare(aSortValue, bSortValue) * -1;
      }
    });
  }

  private compare(a: string | number, b: string | number): number {
    if (typeof a !== typeof b) {
      // Invalid type comparison so treat them as equal
      return 0;
    }
    if (typeof a === "number") {
      const aNum = a as number;
      const bNum = b as number;
      return aNum - bNum;
    } else if (typeof a === "string") {
      const aStr = a as string;
      const bStr = b as string;
      return aStr.localeCompare(bStr);
    } else {
      // Invalid type comparison so treat them as equal
      return 0;
    }
  }

  private header(): JSX.Element {
    return (
      <thead>
        <tr className="win-color-fg-primary">
          {this.props.headers.map((header, index) =>
            this.headerRow(header, index)
          )}
        </tr>
      </thead>
    );
  }

  private headerRow(header: string, index: number) {
    const currentColumnSorted = this.state.currentSortIndex === index;
    return (
      <th
        key={index}
        onClick={() => this.sortIndex(index)}
        className="sortable container-0-2-7 win-color-fg-primary"
      >
        <span>
          {header}{" "}
          {currentColumnSorted ? (
            this.state.isAscending ? (
              <span className="win-icon win-icon-Up icon-0-2-5 icon-0-2-4" />
            ) : (
              <span className="win-icon win-icon-Down icon-0-2-5 icon-0-2-4" />
            )
          ) : (
            <span className="win-icon win-icon-Up icon-0-2-5 icon-0-2-4 place-holder" />
          )}
        </span>
      </th>
    );
  }

  private sortIndex(index: number) {
    const newState = { ...this.state };
    if (newState.currentSortIndex === index) {
      newState.isAscending = !newState.isAscending;
    } else {
      newState.isAscending = true;
      newState.currentSortIndex = index;
    }
    this.setState(newState);
  }

  private body(sortedRows: SortableTableRow[]): JSX.Element {
    return (
      <>
        <tbody>
          {sortedRows.map((row, index) => {
            return (
              <tr key={index}>
                {row.columns.map((column, index) => {
                  return <td key={index}>{column.content}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </>
    );
  }
}
