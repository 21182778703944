import * as React from "react";
import { EligibilityData, EligibilityFactor } from "../../models/model";
import { LocalizationInfo } from "@emt/emt-localization/lib/localization-info";
import { AIContext } from "../../context/AIContext";
import { EnrollmentClient } from "../../clients/enrollment-client";
import { Alert } from "../../components/alert/alert";
import { createScope }  from "@harmony/enablers/react/scope-for-react";
import partnerCenterTheme  from "@harmony/enablers/themes/partner-center";
import button  from "@harmony/enablers/components/button/button";
import progressRing  from "@harmony/enablers/components/progress-ring/progress-ring";
import inlineStatus  from "@harmony/enablers/components/inline-status/inline-status";
import flyInPanel  from "@harmony/enablers/components/fly-in-panel/fly-in-panel";
require("./eligibility-modal.css");

export interface EligibilityModalProps {
    isVisible: boolean;
    onDismiss: () => void ;
    localization: LocalizationInfo;
    mpnNumber: string | null;
    programGuid: string | null;
    programName: string | null;
}

const scope = createScope({
    reactInstance: React,
    styles: [partnerCenterTheme]
  });

const Button = scope.forReact(button);
const ProgressRing = scope.forReact(progressRing);
const InlineStatus = scope.forReact(inlineStatus);
const FlyInPanel = scope.forReact(flyInPanel);

export function EligibilityModal(props: EligibilityModalProps) {

    const [isLoading, setIsLoading] = React.useState(true);
    const [eligibilityData, setEligibilityData] = React.useState<EligibilityData | null>(null);
    const [error, setError] = React.useState<number | null>(null);
    const enrollmentClient = new EnrollmentClient;
    const aiContext = React.useContext(AIContext);

    React.useEffect(() => {
        fetchData();
    }, [props.mpnNumber, props.programGuid])

    function fetchData() {
        setError(null);
        if (props.mpnNumber && props.programGuid) {
            (aiContext as any).appInsights.trackEvent({ name: "OpenEligibilityModal" });
            setIsLoading(true);
            enrollmentClient.getEligibilityForProgramAndMpn(props.mpnNumber, props.programGuid).then(
                (result) => {
                    setEligibilityData(result);
                    setIsLoading(false);
                }).catch((error) => {
                    setError(error.status);
                    setIsLoading(false);
                }
            );
        }
        if (props.mpnNumber === null || props.programGuid === null || props.programName === null) {
            setError(500);
        }
    }

    return (
        <FlyInPanel open={props.isVisible} 
        onHeAfterHide={props.onDismiss} 
        style={{ '--size': 'var(--he-panel-size-small)' }}>
            <div slot="heading" style={{marginTop: 100}}>
                <strong>{`${props.localization.strings["EligibilityFactorsTitle"]} - ${props.programName} (MPN ${props.mpnNumber})`}</strong>
            </div>
            <div>
                    <p className="spacer-md-bottom"> {props.localization.strings["EligibilityModalDescription"]}</p>
                    {isLoading &&
                        (<div className="loader-container"><ProgressRing indeterminate label="Loading..."></ProgressRing></div>)
                    }
                    {!isLoading && error == 401 && (
                        <Alert
                            title={props.localization.strings["NotAuthorizedError"]}
                            content={<p>{props.localization.strings["NotAuthorizedEligibilityError"]} </p>} />
                    )}

                    {!isLoading && error && (
                        <Alert
                            title={props.localization.strings["ErrorFetchingDataTitle"]}
                            content={<p>{props.localization.strings["EligibilityGeneralError"]} <a onClick={fetchData}>{props.localization.strings["TryAgainMessage"]}</a></p>} />
                    )}
                    {!isLoading && !error && (
                        <>
                            
                            <div className="eligibility-factor-list">
                                {
                                    eligibilityData!.isOverridden === null && (
                                        eligibilityData!.factors!.map((f, i) => {
                                            return (factorElement(f, i, props));
                                        })
                                    )
                                }
                                {
                                    eligibilityData!.isOverridden !== null && (
                                        overrideFactor(eligibilityData!.isOverridden, props)
                                    )
                                }
                            </div>
                            <span className="win-color-fg-secondary spacer-md-bottom">{props.localization.strings["EligibilityLastUpdate"] + " " + new  Date(eligibilityData!.processingTimestamp).toLocaleString() + " UTC"}</span>
                        </>
                    )}
                </div>
            <div slot="footer">
                <Button onClick={props.onDismiss} appearance={"primary"}>{props.localization.strings["CloseModalText"]}</Button>
            </div>
    </FlyInPanel>
    );
}

function factorElement(factor: EligibilityFactor, i: number, props: EligibilityModalProps): JSX.Element {
    return (
        <div key={i} className="eligibility-factor">
            {
                factor.isFactorMet
                    ? (<InlineStatus type={"complete"} size={"medium"}/>)
                    : (<InlineStatus type={"incomplete"} size={"medium"}/>)
            }
            <div className="eligibility-factor-text">

                <h6>{props.localization.strings[factor.factorName]}</h6>
                <p>{props.localization.strings[factor.factorName + "Description"]}</p>
                <ul>
                    {
                        factor!.factorDescriptionList!.map((f, i) => {
                            return (<li key={i}>{props.localization.strings[f]}</li>)
                        })
                    }
                </ul>
                {(factor.factorName == "CompetencyFactor") && (<Button appearance={"link"} external target="_blank" href="https://docs.microsoft.com/partner-center/learn-about-competencies">{props.localization.strings["CompetencyFactorLearnMore"]}</Button>)}
                
            </div>
        </div>
        )
}

function overrideFactor(eligible: boolean | null, props: EligibilityModalProps) {
    if (eligible === true) {
        return (
            <div className="eligibility-factor">
                <InlineStatus type={"complete"} size={"medium"}/>
                <div className="eligibility-factor-text">

                    <h6>{props.localization.strings["EligibleFactor"]}</h6>
                    <p>{props.localization.strings["EligibleFactorDescription"]}</p>
                    
                </div>
            </div>
        )
    }

    if (eligible === false) {
        return (
            <div className="eligibility-factor">
                <InlineStatus type={"incomplete"} size={"medium"}/>
                <div className="eligibility-factor-text">
                    <h6>{props.localization.strings["IneligibleFactor"]}</h6>
                    <p>{props.localization.strings["IneligibleFactorDescription"]}</p>

                </div>
            </div>
        )
    }

    return(<></>)
}