export class UxConstants{
    static ProgramsToHideProgramLink = [
        "B9C1E7AC-5F60-4800-99A2-033F4C6775C7", // ENTERPRISE
        "B0556568-3E0F-429E-B17A-32B791C9F943", // SAM
        "3C1B8C00-FBAD-4142-A802-B1D740AD31E5", // SPLA-R
        "62D5111F-D440-40B9-A0E0-E8BCED50F048", // SAVB-SHAREPOINT DEPLOYMENT PLANNING SERVICE
        "C92F180D-7D56-4C1E-8790-3FBCE8482CF1", // SAVB-SKYPE FOR BUSINESS AND EXCHANGE DEPLOYMENT PLANNING SERVICES
        "A928C985-66C2-4B5E-A1FF-5E31E4B9F8F5", // SAVB-PUBLIC CLOUD - WINDOWS AZURE DEPLOYMENT PLANNING SERVICES
        "FAF744D1-0E06-44A9-8E2F-760EC8DF4A0F", // SAVB-DEVELOPER TOOLS DEPLOYMENT PLANNING SERVICES
        "0E4522B5-BE40-40B7-BAED-4EEDA5BBE657", // SAVB-PRIVATE CLOUD MANAGEMENT AND VIRTUALIZATION DEPLOYMENT PLANNING SERVICE
        "49D6F91B-1739-4B5F-B128-77F6DDDDAFAA", // SAVB-SQL SERVER DEPLOYMENT PLANNING SERVICES
        "48BEEC12-0B1F-4844-A02F-4D6F362AA1FB", // SAVB-DYNAMICS DEPLOYMENT PLANNING SERVICES
        "64EC80BF-92AB-40C4-BB39-C4BA4B74F272", //SAVB-SOFTWARE ASSURANCE TRAINING VOUCHER
        "6EB73A5E-CAC9-4341-8AEF-B2ADAB5562D0" // SAVB-DESKTOP DEPLOYMENT PLANNING SERVICES
    ];
}